/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 05/01/2020
 */

import React, { Component } from 'react';
import Header from './header';
import PrayersSanctuarySliders from './prayersSanctuarySliders';
import Footer from './footer';
import reliquia from '../img/SANTA_RELIQUIA.png';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';


export default class PrayerNineDaysOurLadyCoromoto extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange };   
        this.refreshLanguage = this.refreshLanguage.bind(this);  
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }


    render() {
        return (<div>
            <Helmet>
                <title>Novena a Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Novena a Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="novena, coromoto, virgen" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <PrayersSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Novena a Nuestra Se&ntilde;ora de Coromoto</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-12" align="center">
                                        <img src={reliquia} className="img-thumbnail"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p style={{ "text-align": "justify" }}><b>Oración para todos los días</b></p>
                                            <p style={{ "text-align": "justify" }}>
                                                Gloriosa Madre de Dios, Reina y Señora de nuestras almas, vednos postrados a vuestras plantas maternales; venimos a honraros con el recuerdo de vuestra aparición a los indios Cospes en tierras de Coromoto y a implorar vuestro celestial valimento a favor de tantos necesitados entre los cuales ocupa un lugar preferente nuestro pobre corazón. No desoigáis Madre querida de Coromoto la plegaria que hoy te dirigimos a fin de que llegando desde el Cielo el rocío de la gracia que mitigue nuestras penas y dolencias te lo pedimos por Cristo Nuestro Señor. Amén.</p>
                                            <p style={{ "text-align": "justify" }}><b>DÍA PRIMERO</b></p>
                                            <p style={{ "text-align": "justify" }}><b>Consideración: Los Indios Cospes</b></p>
                                            <p style={{ "text-align": "justify" }}>
                                                Entre los Indios que habitan la región de Guanare había una parte designada con el nombre de Cospes. Cuando en 1591 llegaron los españoles allí y se hizo el reparto de tierras e Indios en encomiendas unas cuantas familias de los Cospes se disgregaron de su Tribu y huyeron internándose en las selvas de las dilatadas montañas y valles que se extienden entre Boconó de Trujillo y Guanare hacia las riberas del río Tucupido. En esos lugares apartados se mantuvieron los Cospes muchos años sin acordarse nadie de ellos, hasta que sonó en el reloj de la Providencia la hora de su conversión mediante la maternal intervención de María Santísima. Un español noble y honrado como buen cristiano era llamado Juan Sánchez había obtenido años atrás unos terrenos en la margen derecho del Guanare a unas 5 leguas de la Villa de Espíritu Santo que es la ciudad de Guanare.</p>
                                            <p style={{ "text-align": "justify" }}>
                                                CONSIDERA: Cómo la divina Providencia iba preparando el terreno para la conversión de los pobres Indios que por mucho que se ocultaron siempre tenían sobre sí la mirada de Dios y de su Madre Santísima, ruega para que esta Virgen Bendita siga difundiendo los fulgores de la Fe en las regiones salvajes de los Indios que aún quedan por convertir en nuestra Patria.</p>
                                            <p style={{ "text-align": "justify" }}>
                                                Pida la gracia que desea obtener, rezando luego tres Avemarías alternando con la siguiente jaculatoria:
																												</p>
                                            <p style={{ "text-align": "justify" }}>
                                                Nuestra Señora de Coromoto protectora de Venezuela renovad la Fe en toda la extensión de nuestra Patria.</p>
                                            <p style={{ "text-align": "justify" }}>&nbsp;</p>
                                            <p style={{ "text-align": "justify" }}><b>DÍA SEGUNDO</b></p>
                                            <p style={{ "text-align": "justify" }}><b>Consideración: El Nombre de Coromoto</b></p>
                                            <p style={{ "text-align": "justify" }}>
                                                Varios años llevan los Indios Cospes sin que los civilizadores extranjeros hubieran podido conseguir de ellos descendieran a tomar parte en el aprendizaje del cultivo de los campos y de la Doctrina Cristiana. Estaban, según refieren en la historia, en aquellos lugares de la montaña en donde en donde ellos se habían ocultado hacia las fuentes del río Tucupido y su margen derecha. Aquí se dejó ver la Virgen no solo del Jefe de la Tribu sino de varios otros indios y es también donde les mandó a que fueran a donde los blancos Españoles a que los Bautizaran; por indicación de la Virgen los Indios en número de varios centenares dejaron la montaña y se pusieron a las órdenes de Juan Sánchez quien les fijó para su residencia y trabajo unos campos de su propiedad conocidos con el nombre de Coromoto nombre propio de una quebrada en la confluencia de los ríos Tucupido y Guanare de la cual toma denominación aquel paraje la Santísima Virgen que allí apareció.</p>
                                            <p style={{ "text-align": "justify" }}>
                                                CONSIDERA: La distinción tan grande que la Reina de los Cielos ha tenido a bien hacer  a nuestra Patria y el fervor y santo orgullo con que has de pronunciar el nombre de Coromoto, que lo mismo que el Pilar para España o Lourdes para Francia es para nuestra Patria Venezuela la gloria más alta y el recuerdo más sagrado.</p>
                                            <p style={{ "text-align": "justify" }}>&nbsp;</p>
                                            <p style={{ "text-align": "justify" }}><b>DÍA TERCERO</b></p>
                                            <p style={{ "text-align": "justify" }}><b>Consideración: Primera Aparición de la Virgen a los Indios Cospes</b></p>
                                            <p style={{ "text-align": "justify" }}>
                                                Según refiere la Historia la Virgen de Coromoto apareció repetidas veces a los Indios Cospes y no solamente a los mayores sino también a los hijos de estos según testimonio de los que fueron Párrocos de Guanare en tiempos no lejanos de las apariciones. La Primera Aparición tuvo lugar en el año de año 1651 cuando el Cacique de los Cospes se dirigía en compañía de su mujer al trabajo de campo. Al llegar a una quebrada una hermosa Señora que sostenía en sus brazos un precioso niño, apareció caminando sobre las aguas diciéndoles en lengua propia de los Indios “que salieran a donde estaban los blancos para que les echaran agua sobre sus cabezas para subir al Cielo”.
																												</p>
                                            <p style={{ "text-align": "justify" }}>
                                                CONSIDERA: Cómo también te llama a ti la gracia Divina por medio del Culto amoroso de la Virgen de Coromoto a que cumplas tus deberes de Cristiano, y con frecuencia resistes el llamamiento de la gracia; no quieres imitar al Indio rebelde sino entra por el camino de la Ley Divina y probarás que el amor a la Virgen de Coromoto es sincero. Amén.</p>
                                            <p style={{ "text-align": "justify" }}>&nbsp;</p>
                                            <p style={{ "text-align": "justify" }}><b>DÍA CUARTO</b></p>
                                            <p style={{ "text-align": "justify" }}><b>Consideración: Segunda Aparición de la Virgen de Coromoto</b></p>
                                            <p style={{ "text-align": "justify" }}>
                                                El Cacique de los Cospes que en un principio asistía gustoso a las instrucciones catequistas disgustó luego añorando sin duda la soledad de los bosques y se retiró sin recibir el bautismo. Triste y meditabundo estaba el Indio recostado a su barbacoa días antes de su fuga. Con él se hallaba su esposa, su hermana Isabel y un hijo de éste el cual era llamado Juan. Todo era silencio en la noche pues las Indias al ver tan mal humor al Cacique no se atrevían a decir palabra. Habrían transcurrido algunos instantes desde la llegada del Cacique cuando de modo visible y corpóreo la Virgen Santísima se presentó en el umbral del bohío despidiendo rayos abundantes como los rayos del sol a medio día, según palabras de la India Isabel, que iluminaron el recinto con celestial claridad. Al verle el Cacique pensó que la Gran Señora venía a reprocharle su mal proceder y le dijo: “¿Hasta cuando me quieres perseguir? Bien te puedes volver no he de hacer más lo que tu me mandes. Por ti dejé mis conucos y he venido a pasar trabajo, como las mujeres reprendieran al Indio el modo como trataba a la Bella Mujer le dijo a la Virgen que lo miraba sonriente: ¿con matarte me dejarás? Adelantándose entonces la Virgen hacia el Cacique él se abalanzó sobre ella para asirla del brazo y echarla fuera quedando todo envuelto en tinieblas. Aquí la tengo dice el Indio en tono de furia y triunfo. Muéstrala para verla responden las mujeres y a la luz de las ascuas que arden en el tosco fogón abrió el Indio su mano y contemplan todos el retrato de la bella mujer.
																												</p>
                                            <p style={{ "text-align": "justify" }}>
                                                Considera las Ternuras Maternales.
                                                 María que aún viéndose maltratada por aquel hijo rebelde responde con generosidad y largueza dejándonos esa reliquia tan preciosa de su Imagen.
																												</p>

                                            <p style={{ "text-align": "justify" }}>&nbsp;</p>
                                            <p style={{ "text-align": "justify" }}><b>DÍA QUINTO</b></p>
                                            <p style={{ "text-align": "justify" }}><b>Consideración: Conversión y muerte del Cacique</b></p>
                                            <p style={{ "text-align": "justify" }}>
                                                El pobre Indio resistió por algún tiempo más la acción de la gracia que golpeaba insistentemente a las puertas de su corazón pero al fin la Virgen salió triunfante pues el Cacique al verse mortalmente herido por la mordedura de una serpiente cuando huía a la espesura de la selva pidió a gritos el Bautismo que le fue suministrado por un buen Cristiano de la Ciudad de Barinas.
																												</p>
                                            <p style={{ "text-align": "justify" }}>
                                                CONSIDERA: La gran solicitud de María para conseguir la conversión de aquel Indio rebelde se le aparece repetidas veces le sigue con maternal insistencia sin retroceder ante su intento criminal y hasta permite que una serpiente le hiera de muerte para que su alma nazca a la vida de la gracia y se salve mediante una sincera conversión sellada con el bautismo. ¡Cómo es posible que se pierda aquel a quien María toma bajo su singular protección! Acuda a María de Coromoto con singular confianza seguro de que te alcanzará el remedio de todos tus males sobre todo el triunfo sobre los enemigos de tu alma que pretenden impedir tu eterna salvación.
																												</p>

                                            <p style={{ "text-align": "justify" }}>&nbsp;</p>


                                            <p style={{ "text-align": "justify" }}><b>DÍA SEXTO</b></p>
                                            <p style={{ "text-align": "justify" }}><b>Consideración: La Virgen de Coromoto Apóstol de Venezuela</b></p>
                                            <p style={{ "text-align": "justify" }}>
                                                De las palabras que la Virgen dijo al Cacique y a los Indios Cospes desde la primera de sus apariciones que, “Salera a donde estaban los Blancos cabeza para ir al Cielo”, se deduce que la Virgen se constituyó en Misionera de los Indios Venezolanos siendo en la Aurora de la Fe del nuevo mundo como un sol brillantísimo que irradiara los más fulgurantes resplandores. Mas no hizo sus apariciones solo para convertir a los Indios sino que dejó su retrato en esta tierra privilegiada para que la iluminara en la sucesión de los tiempos. No significa otra cosa los múltiples prodigios que su poder ha obra a favor de sus devotos y continúa obrando en el orden de la naturaleza como el de la gracia para conservar siempre el vivo rescoldo de la Fe en el pueblo Venezolano. Considera cómo también tú debes a imitación de la Virgen de Coromoto trabajar en la salvación de las almas.
																												</p>

                                            <p style={{ "text-align": "justify" }}>&nbsp;</p>
                                            <p style={{ "text-align": "justify" }}><b>DÍA SEPTIMO</b></p>
                                            <p style={{ "text-align": "justify" }}><b>Consideración: La Virgen de Coromoto Madre de Venezuela</b></p>
                                            <p style={{ "text-align": "justify" }}>
                                                Considera como Virgen Santísima quiso aparecer en Coromoto el día en que se celebraba la fiesta de su nacimiento entre los hombres para demostrarte la gran predilección que por tí tuvo desde los primeros instantes de tu vida, ahora lleno de agradecimiento la Providencia Divina que en el amanecer de este nuevo mundo a las luces de la Fe y de la civilización  envió a los Indios Venezolanos la Aurora de la gracia para que les diera la vida cristiana enseñándoles el camino que conduce a la Iglesia Católica. Trata finalmente de corresponder tú también al llamamiento que como a los Indios Cospes te hace tu Madre Celestial para que entre de lleno en la práctica de los deberes cristianos a fin de manifestarte verdadero hijo de la Virgen de Coromoto.
																												</p>
                                            <p style={{ "text-align": "justify" }}>&nbsp;</p>
                                            <p style={{ "text-align": "justify" }}><b>DÍA OCTAVO</b></p>
                                            <p style={{ "text-align": "justify" }}><b>Consideración: La Virgen de Coromoto Protectora de Venezuela</b></p>
                                            <p style={{ "text-align": "justify" }}>
                                                Muchos son los prodigios con que la Virgen de Coromoto ha favorecido a sus hijos que llenos de confianza han acudido a su regazo. La Historia que no se ha escrito y que tal vez no se escribirá es más elocuente para los innumerables testigos de las bondades de María que todos los libros que se escriben pueden con los favores públicos por la misma Virgen concedidos. Quién no podrá contar alguna gracia así propia concedida por la Virgen de Coromoto. El resurgir de la Fe obrada por la Virgen de Coromoto  es uno de los favores generales concedidos a Venezuela entera por la Virgen de Coromoto una de las obras más patentes con que ha demostrado la protección especial que dispensa al pueblo venezolano. Quién no es testigo de este celestial resurgimiento tanto general como particular manifestado en las peregrinaciones nacionales a Guanare. Dá gracias a María Santísimo por tan singular protección y procura hacerte digno con tu conducta de merecerte tú particularmente.
																												</p>


                                            <p style={{ "text-align": "justify" }}>&nbsp;</p>


                                            <p style={{ "text-align": "justify" }}><b>DÍA NOVENO</b></p>
                                            <p style={{ "text-align": "justify" }}><b>Consideración: La Virgen de Coromoto Protectora de Venezuela</b></p>
                                            <p style={{ "text-align": "justify" }}>
                                                Muchas son las devociones marianas como las distintas advocaciones de la Santísima virgen en nuestra Patria. Todas son sin duda dignas de consideración y aprecio pero ninguna como la de la Virgen de Coromoto. Ella es la primera en el orden del tiempo pues ninguna como ella puede remontarse a los tiempos de la Evangelización primitiva de los Indios cuando la Fe empezaba a nacer en tierras Americanas. Elle es la única que tiene por origen la aparición real de la Santísima Virgen en persona a nuestros aborígenes dejándoles el don inestimable de su imagen. Ella es la que con más fuerza atrae los corazones de toda Venezuela pues desde las remotas regiones acudieron y acuden llenos de Fe y amor los peregrinos. Ella por tanto tiene más derechos que ninguna otra devoción a ser proclamada Patrona Celestial de nuestra Patria. Proclámala tú muy alto en el fondo de tu corazón, entronízala en tu hogar y conviértete en apóstol de la devoción a Nuestra Señora de Coromoto.</p>
                                            <p style={{ "text-align": "justify" }}>Amén.</p>
                                            <p style={{ "text-align": "justify" }}>&nbsp;</p>                                    
                                        </article>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12"><Link to="prayersFiles/Novena-Nuestra-Señora-Coromoto.pdf" target="_blank" onClick={this.trackPDF}>Novena a Nuestra Se&ntilde;ora de Coromoto (descargar PDF)</Link></div>
                                </div>
                                <div className="row">
                                    <div className="col-12" align="center">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">Bibliografía: https://basilicacatedralguanare.wordpress.com/novena-a-la-virgen-de-coromoto</div>
                                </div>
                                <div className="row">
                                    <div className="col-12" align="center">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div class="col-12" align="center"><a href="oraciones-virgen-de-coromoto.html">Retornar</a></div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}