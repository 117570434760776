


import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Index from './components/index.jsx';
import ApparitionHistory from './components/apparitionHistory.jsx';
import SanctuaryHistory from './components/sanctuaryHistory.jsx';
import CatedralGuanareHistory from './components/catedralGuanareHistory.jsx';
import MisionSanctuary from './components/misionSanctuary.jsx';
import OrganizationChartSanctuary from './components/organizationChartSanctuary.jsx';
import BishopGuanare from './components/bishopGuanare.jsx';
import RectorSanctuary from './components/rectorSanctuary.jsx';
import TimtableSanctuary from './components/timtableSanctuary.jsx';
import SacramentsSanctuary from './components/sacramentsSanctuary.jsx';
import LocationSanctuary from './components/locationSanctuary.jsx';
import DonationsSanctuary from './components/donationsSanctuary.jsx';
import PrayersSanctuary from './components/prayersSanctuary.jsx';
import PrayerNineDaysOurLadyCoromoto from './components/prayerNineDaysOurLadyCoromoto.jsx';
import DeclareOurLadyCoromoto from './components/declareOurLadyCoromoto.jsx';
import ConsecrateVenezuelaVirginMary from './components/consecrateVenezuelaVirginMary.jsx';
import ConsecrateVenezuelaOurLadyCoromoto from './components/consecrateVenezuelaOurLadyCoromoto.jsx';
import NinthTrutsOurLadyCoromoto from './components/ninthTrutsOurLadyCoromoto.jsx';
import HolyRosaryOurLadyCoromoto from './components/holyRosaryOurLadyCoromoto.jsx';
import VideosSanctuary from './components/videosSanctuary.jsx';
import MultimediaSanctuary from './components/multimediaSanctuary.jsx';
import FindingsHolyRelic from './components/findingsHolyRelic.jsx';
import PastoralLetter from './components/PastoralLetter.jsx';
import KnowingHolyRelicIntro from './components/KnowingHolyRelicIntro.jsx';
import KnowingHolyRelicDetail from './components/KnowingHolyRelicDetail.jsx';
import TourVirtualSanctuaryRedirect from './components/TourVirtualSanctuaryRedirect.jsx';
import TourVirtualCathedralGuanare from './components/TourVirtualCathedralGuanare.jsx';
import TourVirtualMusseumCoromoto from './components/TourVirtualMusseumCoromoto.jsx';
import Links from './components/Links.jsx';
import Blessed from './components/Blessed.jsx';
import BlessedMariaSanJose from './components/BlessedMariaSanJose.jsx';
import BlessedCandelariaSanJose from './components/BlessedCandelariaSanJose.jsx';
import BlessedCarmenRendiles from './components/BlessedCarmenRendiles.jsx';
import ContactUs from './components/ContactUs.jsx';
import GalleryPageSliders from './components/GalleryPageSliders.jsx';
import HolyRosary from './components/HolyRosary.jsx';
import IntroHolyRosary from './components/IntroHolyRosary.jsx';
import DiocesanSeminary from './components/DiocesanSeminary.jsx';
import NotFound from './components/NotFoundPage.jsx';
import MayMonthHolyVirginMary from './components/MayMonthHolyVirginMary.jsx';
import VenerableEmiliaSanJose from './components/VenerableEmiliaSanJose.jsx';
import Venerables from './components/Venerables.jsx';
import BlessedJoseGregorioHernandez from './components/BlessedJoseGregorioHernandez.jsx';
import VenerableMarcelinaSanJose from './components/VenerableMarcelinaSanJose.jsx';
import VenerableTomasMoralesPerez from './components/VenerableTomasMoralesPerez.jsx';
import SaintJoseph from './components/SaintJoseph.jsx';
import Homily369 from './components/Homily369Sep2021Component.jsx';
import Celebration369ApparitionComponent from './components/Celebration369ApparitionComponent.jsx';
import NectarioMariaBiography from './components/NectarioMariaBiography.jsx';
import NewsHome from './components/NewsHome.jsx';
import Program370Component from './components/ProgramCelebration370.jsx';
import Homily370Sep2022Component from './components/Homily370Sep2022Component.jsx';
import TourVirtuaCathedralRedirect from './components/TourVirtuaCathedralRedirect.jsx';
import ReactGA from "react-ga4";


class App extends Component {

  constructor(props) {
    super(props);
    let language = this.props.language != null ? this.props.language : "es";
    let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
    this.state = {lang: language, eventChangeLanguage: onLanguageChange };
    ReactGA.initialize("G-41VDDYGSTV");
  }


  render() {   
    return (
      <Router>
        <Switch>
          <Route exact path="/"  render={(props) => <Index language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />          
          <Route exact path="/historia-virgen-coromoto.html" render={(props) => <ApparitionHistory language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/historia-santuario-nacional-virgen-coromoto.html"  render={(props) => <SanctuaryHistory language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/historia-catedral-guanare.html"  render={(props) => <CatedralGuanareHistory language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/mision-santuario-nacional-virgen-coromoto.html"   render={(props) => <MisionSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/organigrama-santuario-nacional-virgen-coromoto.html" render={(props) => <OrganizationChartSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/obispo-diocesis-guanare.html" render={(props) => <BishopGuanare language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/rector-santuario-nacional-virgen-coromoto.html" render={(props) => <RectorSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/horario-misa-santuario-nacional-virgen-coromoto.html"  render={(props) => <TimtableSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/sacramentos-santuario-nacional-virgen-coromoto.html"  render={(props) => <SacramentsSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/ubicacion-santuario-virgen-coromoto.html"  render={(props) => <LocationSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/donaciones-santuario-nacional-virgen-coromoto.html"  render={(props) => <DonationsSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/oraciones-virgen-de-coromoto.html"  render={(props) => <PrayersSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/novena-virgen-coromoto.html"  render={(props) => <PrayerNineDaysOurLadyCoromoto language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/breve-pontificio-patronato-coromotano.html" render={(props) => <DeclareOurLadyCoromoto language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/acto-consagracion-venezuela-santisima-virgen.html" render={(props) => <ConsecrateVenezuelaVirginMary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/oracion-consagracion-venezuela-virgen-coromoto.html" render={(props) => <ConsecrateVenezuelaOurLadyCoromoto language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/novena-confianza-nuestra-senora-coromoto.html" render={(props) => <NinthTrutsOurLadyCoromoto language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/virgen-coromoto-rosario.html"  render={(props) => <HolyRosaryOurLadyCoromoto language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/videos-santuario-nacional-virgen-coromoto.html" render={(props) => <VideosSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/descargas-multimedia-santuario-nacional-virgen-coromoto.html" render={(props) => <MultimediaSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/hallazgos-santa-reliquia-virgen-coromoto.html" render={(props) => <FindingsHolyRelic language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/carta-pastoral-santa-reliquia-virgen-coromoto.html" render={(props) => <PastoralLetter language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/santa-reliquia-virgen-coromoto.html" render={(props) => <KnowingHolyRelicIntro language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/ver-santa-reliquia-virgen-coromoto.html" render={(props) => <KnowingHolyRelicDetail language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/tour-virtual-santuario-nacional.html" render={(props) => <TourVirtualSanctuaryRedirect language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/tour-virtual-catedral-guanare.html" render={(props) => <TourVirtualCathedralGuanare language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/museo-santuario-nacional-virgen-coromoto.html" render={(props) => <TourVirtualMusseumCoromoto language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/links.html" render={(props) => <Links language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/beatos-venezuela.html" render={(props) => <Blessed language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/beata-maria-san-jose.html" render={(props) => <BlessedMariaSanJose language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/beata-candelaria-san-jose.html" render={(props) => <BlessedCandelariaSanJose language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/beata-madre-carmen-rendiles.html" render={(props) => <BlessedCarmenRendiles language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />
          <Route exact path="/contactenos.html" render={(props) => <ContactUs language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/imagenes-hallazgos-reliquia-virgen-coromoto.html" render={(props) => <GalleryPageSliders language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/santo-rosario.html" render={(props) => <HolyRosary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/intro-santo-rosario.html" render={(props) => <IntroHolyRosary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/seminario-diocesano-nuestra-senora-coromoto.html" render={(props) => <DiocesanSeminary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/mayo-mes-virgen-maria.html" render={(props) => <MayMonthHolyVirginMary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/venerable-emilia-san-jose.html" render={(props) => <VenerableEmiliaSanJose language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/venerables-venezuela.html" render={(props) => <Venerables language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/beato-jose-gregorio-hernandez.html" render={(props) => <BlessedJoseGregorioHernandez language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/venerable-marcelina-san-jose.html" render={(props) => <VenerableMarcelinaSanJose language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/venerable-tomas-morales-perez.html" render={(props) => <VenerableTomasMoralesPerez language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/oraciones-san-jose.html" render={(props) => <SaintJoseph language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          
          <Route exact path="/history-our-lady-of-coromoto.html" render={(props) => <ApparitionHistory language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/history-sanctuary-our-lady-of-coromoto.html"  render={(props) => <SanctuaryHistory language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/history-cathedral-guanare.html"  render={(props) => <CatedralGuanareHistory language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/findings-holy-relic-our-lady-of-coromoto.html" render={(props) => <FindingsHolyRelic language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />          
          <Route exact path="/pastoral-letter-holy-relic-coromoto.html" render={(props) => <PastoralLetter language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />          
          <Route exact path="/knowing-holy-relic-our-lady-of-coromoto.html" render={(props) => <KnowingHolyRelicIntro language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />          
          <Route exact path="/mission-basilica-minor-national-sanctuary-of-our-lady-of-coromoto.html"   render={(props) => <MisionSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/organization-chart-sanctuary-our-lady-of-coromoto.html" render={(props) => <OrganizationChartSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/bishop-diocese-of-guanare.html" render={(props) => <BishopGuanare language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/rector-sanctuary-our-lady-of-coromoto.html" render={(props) => <RectorSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/mass-schedule-minor-basilica-our-lady-coromoto.html"  render={(props) => <TimtableSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/sacraments-minor-basilica-our-lady-coromoto.html"  render={(props) => <SacramentsSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/location-our-lady-of-coromoto.html"  render={(props) => <LocationSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/donations-sanctuary-our-lady-of-coromoto.html"  render={(props) => <DonationsSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/prayers-to-our-lady-of-coromoto.html"  render={(props) => <PrayersSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/videos-our-lady-of-coromoto.html" render={(props) => <VideosSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />                 
          <Route exact path="/download-multimedia-our-lady-of-coromoto.html" render={(props) => <MultimediaSanctuary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />              
          <Route exact path="/blessed.html" render={(props) => <Blessed language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />          
          <Route exact path="/contact_us.html" render={(props) => <ContactUs language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/images-findings-our-lady-of-coromoto.html" render={(props) => <GalleryPageSliders language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/view-holy-relic-our-lady-of-coromoto.html" render={(props) => <KnowingHolyRelicDetail language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />          
          <Route exact path="/diocesan-seminary-our-lady-of-coromoto.html" render={(props) => <DiocesanSeminary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/may-month-holy-virgin-mary.html" render={(props) => <MayMonthHolyVirginMary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/prayers-saint-joseph.html" render={(props) => <SaintJoseph language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/intro-holy-rosary.html" render={(props) => <IntroHolyRosary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />         
          <Route exact path="/holy-rosary.html" render={(props) => <HolyRosary language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/homilia-369-aparicion-virgen-coromoto.html" render={(props) => <Homily369 language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/homily-369-years-coromoto.html" render={(props) => <Homily369 language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/celebracion-369-aparicion-virgen-coromoto.html" render={(props) => <Celebration369ApparitionComponent language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/celebration-369-apparition-coromoto.html" render={(props) => <Celebration369ApparitionComponent language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/hermano-nectario-maria.html" render={(props) => <NectarioMariaBiography language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/brother-nectario-maria.html" render={(props) => <NectarioMariaBiography language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/noticias-santuario-virgen-coromoto.html" render={(props) => <NewsHome language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/news-our-lady-coromoto.html" render={(props) => <NewsHome language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/programa-370-virgen-coromoto.html" render={(props) => <Program370Component language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/program-370-years-lady-coromoto.html" render={(props) => <Program370Component language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/homilia-370-aparicion-virgen-coromoto.html" render={(props) => <Homily370Sep2022Component language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/homily-370-years-coromoto.html" render={(props) => <Homily370Sep2022Component language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
          <Route exact path="/tour-virtual2.html" render={(props) => <TourVirtuaCathedralRedirect language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />}  />

          <Route component={NotFound} render={(props) => <NotFound language={this.state.language} onLanguageChange={this.state.eventChangeLanguage} {...props} />} />
        </Switch>
      </Router>
    );
  }
}
export default App;
