/**
 * DeclareOurLadyCoromoto 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 11/10/2021
 */

import React, { Component } from 'react';
import Header from './header';
import PrayersSanctuarySliders from './prayersSanctuarySliders';
import Footer from './footer';
import reliquia from '../img/SANTA_RELIQUIA.png';
import {Helmet} from "react-helmet";
import { Link } from 'react-router-dom';



export default class NinthTrutsOurLadyCoromoto extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }


    render() {
        return (<div>
            <Helmet>
                <title>Novena de Confianza a Nuestra Se&ntilde;ora de Coromoto</title>
                <meta name="description" content="Novena de Confianza a Nuestra Se&ntilde;ora de Coromoto" />
                <meta name="keywords" content="novena, coromoto, virgen, maria" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <PrayersSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header"><h3>Novena de Confianza a Nuestra Se&ntilde;ora de Coromoto</h3>
                            </div>
                            <div className="card-body">
                                <div class="row">
                                    <div className="col-12" align="center">
                                        <img src={reliquia} className="img-thumbnail"/>
                                    </div>
                                </div>
                                <div className="row">
                                    <div class="col-12">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <article>
                                            <p style={{ "text-align": "justify" }}>&iexcl;Oh Virgen de Coromoto! En tus manos pongo esta s&uacute;plica: <strong>(Tal intenci&oacute;n)</strong>; Pres&eacute;ntala al coraz&oacute;n de Jes&uacute;s. Haz valer tu amor de Madre y tu poder de Reina.</p>
                                            <p style={{ "text-align": "justify" }}>&iexcl;Oh Mar&iacute;a! Yo cuento con tu ayuda. Yo conf&iacute;o en tu poder. Yo me entrego a tu voluntad. &iexcl;Yo estoy seguro de tu misericordia! &iexcl;Madre de Dios y Madre m&iacute;a! Ruega por m&iacute;. Ll&eacute;vame al Coraz&oacute;n de Jes&uacute;s.</p>
                                            <p style={{ "text-align": "justify" }}><strong>Oraci&oacute;n:</strong></p>
                                            <p style={{ "text-align": "justify" }}>Inmaculada Mar&iacute;a de Coromoto. Madre Amorosa. T&uacute; que conoces nuestra necesidades, confiados esperamos de tu gran misericordia el remedio de ellas. Madre m&iacute;a, fortalece nuestra voluntad, inflama nuestros corazones en llamas puras de amor divino, purifica nuestros labios para que sin cesar te canten. T&uacute; eres el consuelo de los afligidos y la esperanza de los que en ti confiamos. Virgen de Coromoto, ten misericordia de nosotros y del mundo entero. Am&eacute;n.</p>
                                            <p style={{ "text-align": "justify" }}><strong>Bibliograf&iacute;a:</strong></p>
                                            <p style={{ "text-align": "justify" }}>Santo Rosario en Honor de la Virgen de Coromoto, Preparado por Mons. Jos&eacute; S. Valero Ruz V Obispo de Guanare, Guanare 2011</p>
                                        </article>																											
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12"><Link to="prayersFiles/Novena-Confianza-Nuestra-Señora-Coromoto.pdf" target="_blank" onClick={this.trackPDF}>Novena de Confianza a Nuestra Se&ntilde;ora de Coromoto (descargar PDF)</Link></div>
                                </div>
                                <div className="row">
                                    <div className="col-12" align="center">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <div class="col-12" align="center"><a href="oraciones-virgen-de-coromoto.html">Retornar</a></div>
                                </div>
                            </div>
                        </div>                        
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                


            </div>
            <Footer />
        </div>);
    }

}