/**
 * TimtableSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 11/02/2024
 */

import React, { Component } from 'react';
import Header from './header';
import PrayersSanctuarySliders from './prayersSanctuarySliders';
import Footer from './footer';
import { Helmet } from "react-helmet";


export default class VideosSanctuary extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : "es";
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };
        }
        else {
            this.state = { lang: language, eventChangeLanguage: onLanguageChange };
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
    }

    componentDidMount() {
        if (sessionStorage.getItem('language') !== null) {
            if (this.state.eventChangeLanguage !== null && typeof this.state.eventChangeLanguage !== "undefined") {
                this.setState({ lang: sessionStorage.getItem('language') });
                sessionStorage.setItem('language', sessionStorage.getItem('language'));
                this.state.eventChangeLanguage(sessionStorage.getItem('language'));
            }
        }
    }

    refreshLanguage(value) {
        if (value !== null && value !== '') {
            this.setState({ lang: value });
        }
    }

    render() {
        return (<div>
            <Helmet>
                <title>Videos Virgen de Coromoto Patrona de Venezuela</title>
                <meta name="description" content="Videos Virgen de Coromoto Patrona de Venezuela" />
                <meta name="keywords" content="videos, basilica, santuario, virgen, coromoto" />
            </Helmet>
            <Header language={this.state.lang} onLanguageChange={this.state.eventChangeLanguage} sendData={this.refreshLanguage} />
            <PrayersSanctuarySliders />
            <div className="container">
                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row" >
                    <div class="col-12" style={{ "color": "#927f26", "fontWeight" : "bold" }} align="center"><h2>Videos Basílica Menor Santuario Nacional de Nuestra Señora de Coromoto</h2></div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                <div className="row">
                    <div class="col-md-6 d-lg-none d-md-none d-sm-none d-none d-xl-block" align="center">
                        <div className="row"><div class="col-md-12">
                            <iframe  width="550" height="390" src="https://www.youtube.com/embed/MX7R5SOzr78?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Historia de la Aparición de la Virgen de Coromoto" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Historia de la Aparición de la Virgen de Coromoto</h3></div></div>
                    </div>
                    <div className="col-md-6 d-lg-none d-md-none d-sm-none d-none d-xl-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="550" height="390" src="https://www.youtube.com/embed/8fRyWqrOidA?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="EL RESPLANDOR DE LA BELLA SEÑORA" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>EL RESPLANDOR DE LA BELLA SEÑORA</h3></div></div>
                    </div>
                </div>
                <div className="row">
                    <div class="col-md-6 d-xl-none d-md-none d-sm-none d-none d-sm-none d-lg-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="450" height="350" src="https://www.youtube.com/embed/MX7R5SOzr78?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Historia de la Aparición de la Virgen de Coromoto"  allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Historia de la Aparición de la Virgen de Coromoto</h3></div></div>
                    </div>
                    <div class="col-md-6 d-xl-none d-md-none d-sm-none d-none d-sm-none d-lg-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="450" height="350" src="https://www.youtube.com/embed/8fRyWqrOidA?autoplay=0" allowfullscreen="allowfullscreen" title="EL RESPLANDOR DE LA BELLA SEÑORA"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>EL RESPLANDOR DE LA BELLA SEÑORA</h3></div></div>
                    </div>      
                </div>
                <div className="row">
                    <div class="col-md-6 d-xl-none d-lg-none d-sm-none d-none d-md-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="350" height="300" src="https://www.youtube.com/embed/MX7R5SOzr78?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Historia de la Aparición de la Virgen de Coromoto"  allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Historia de la Aparición de la Virgen de Coromoto</h3></div></div>
                    </div>
                    <div class="col-md-6 d-xl-none d-lg-none d-sm-none d-none d-md-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="350" height="300" src="https://www.youtube.com/embed/8fRyWqrOidA?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="EL RESPLANDOR DE LA BELLA SEÑORA" allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>EL RESPLANDOR DE LA BELLA SEÑORA</h3></div></div>
                    </div>         
                </div>    
                <div className="row">
                    <div class="col-md-5 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="500" height="400" src="https://www.youtube.com/embed/MX7R5SOzr78?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Historia de la Aparición de la Virgen de Coromoto"  allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Historia de la Aparición de la Virgen de Coromoto</h3></div></div>
                    </div>
                    <div class="col-md-2 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">&nbsp;</div>
                    <div class="col-md-5 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="500" height="400" src="https://www.youtube.com/embed/8fRyWqrOidA?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="EL RESPLANDOR DE LA BELLA SEÑORA"  allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>EL RESPLANDOR DE LA BELLA SEÑORA</h3></div></div>
                    </div>
                </div>     
                <div className="row">
                    <div class="col-md-5 d-xl-none d-lg-none d-sm-none d-md-none d-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="100%" height="300" src="https://www.youtube.com/embed/MX7R5SOzr78?autoplay=0" 
                        title="Historia de la Aparición de la Virgen de Coromoto"  allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Historia de la Aparición de la Virgen de Coromoto</h3></div></div>
                    </div>
                    <div class="col-md-2 d-xl-none d-lg-none d-sm-none d-md-none d-block" align="center">&nbsp;</div>
                    <div class="col-md-5 d-xl-none d-lg-none d-sm-none d-md-none d-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="100%" height="300" src="https://www.youtube.com/embed/8fRyWqrOidA?autoplay=0" 
                         title="EL RESPLANDOR DE LA BELLA SEÑORA"  allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>EL RESPLANDOR DE LA BELLA SEÑORA</h3></div></div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                {/* Block 2 */}
                

                <div className="row">
                    <div class="col-md-6 d-lg-none d-md-none d-sm-none d-none d-xl-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="550" height="390" src="https://www.youtube.com/embed/XFhjAb9nY9o?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Conferencia sr Pablo González en el Santuario Nacional 03 Febrebro del 2024"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Conferencia sr Pablo González en el Santuario Nacional 03 Febrebro del 2024</h3></div></div>
                    </div>
                    <div class="col-md-6 d-lg-none d-md-none d-sm-none d-none d-xl-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="550" height="390" src="https://www.youtube.com/embed/vmlwC6PFqzQ?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Conferencia sra Nancy Jiménez en el Santuario Nacional 03 Febrebro del 2024"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Conferencia sra Nancy Jiménez en el Santuario Nacional 03 Febrebro del 2024</h3></div></div>
                    </div>
                </div>
                <div className="row">
                    <div class="col-md-6 d-xl-none d-md-none d-sm-none d-none d-sm-none d-lg-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe   width="450" height="350" src="https://www.youtube.com/embed/XFhjAb9nY9o?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Conferencia sr Pablo González en el Santuario Nacional 03 Febrebro del 2024"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Conferencia sr Pablo González en el Santuario Nacional 03 Febrebro del 2024</h3></div></div>
                    </div>
                    <div class="col-md-6 d-xl-none d-md-none d-sm-none d-none d-sm-none d-lg-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe   width="450" height="350" src="https://www.youtube.com/embed/vmlwC6PFqzQ?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Conferencia sra Nancy Jiménez en el Santuario Nacional 03 Febrebro del 2024"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Conferencia sra Nancy Jiménez en el Santuario Nacional 03 Febrebro del 2024</h3></div></div>
                    </div>
                </div>
                <div className="row">
                    <div class="col-md-6 d-xl-none d-lg-none d-sm-none d-none d-md-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="350" height="300" src="https://www.youtube.com/embed/XFhjAb9nY9o?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Conferencia sr Pablo González en el Santuario Nacional 03 Febrebro del 2024"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Conferencia sr Pablo González en el Santuario Nacional 03 Febrebro del 2024</h3></div></div>
                    </div>
                    <div class="col-md-6 d-xl-none d-lg-none d-sm-none d-none d-md-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="350" height="300" src="https://www.youtube.com/embed/vmlwC6PFqzQ?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Conferencia sra Nancy Jiménez en el Santuario Nacional 03 Febrebro del 2024"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Conferencia sra Nancy Jiménez en el Santuario Nacional 03 Febrebro del 2024</h3></div></div>
                    </div>
                </div>


                <div className="row">
                    <div class="col-md-5 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="500" height="400" src="https://www.youtube.com/embed/XFhjAb9nY9o?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Conferencia sr Pablo González en el Santuario Nacional 03 Febrebro del 2024"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Conferencia sr Pablo González en el Santuario Nacional 03 Febrebro del 2024</h3></div></div>
                    </div>
                    <div class="col-md-2 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">&nbsp;</div>
                    <div class="col-md-5 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="500" height="400" src="https://www.youtube.com/embed/vmlwC6PFqzQ?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Conferencia sra Nancy Jiménez en el Santuario Nacional 03 Febrebro del 2024"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Conferencia sra Nancy Jiménez en el Santuario Nacional 03 Febrebro del 2024</h3></div></div>
                    </div>
                </div>


                <div className="row">
                    <div class="col-md-5 d-xl-none d-lg-none d-sm-none d-md-none d-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="100%" height="300" src="https://www.youtube.com/embed/XFhjAb9nY9o?autoplay=0" 
                         title="Conferencia sr Pablo González en el Santuario Nacional 03 Febrebro del 2024"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Conferencia sr Pablo González en el Santuario Nacional 03 Febrebro del 2024</h3></div></div>
                    </div>
                    <div class="col-md-2 d-xl-none d-lg-none d-sm-none d-md-none d-block" align="center">&nbsp;</div>
                    <div class="col-md-5 d-xl-none d-lg-none d-sm-none d-md-none d-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="100%" height="300" src="https://www.youtube.com/embed/vmlwC6PFqzQ?autoplay=0" 
                         title="Conferencia sra Nancy Jiménez en el Santuario Nacional 03 Febrebro del 2024"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Conferencia sra Nancy Jiménez en el Santuario Nacional 03 Febrebro del 2024</h3></div></div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>

                 {/* Block 3 */}


                <div className="row">
                    <div class="col-md-6 d-lg-none d-md-none d-sm-none d-none d-xl-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="550" height="390" src="https://www.youtube.com/embed/w9PS__-EiQA?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Testimonio del Presbítero Allender Hernandez Rector de la Basílica Menor de Nuestra Señora de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Testimonio del Presbítero Allender Hernandez Rector de la Basílica Menor de Nuestra Señora de Coromoto 03 Febrebro del 2024</h3></div></div>
                    </div>
                    <div class="col-md-6 d-lg-none d-md-none d-sm-none d-none d-xl-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="550" height="390" src="https://www.youtube.com/embed/aYDscqFGv_4?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Hallazgos Santa Reliquia Virgen de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Hallazgos Santa Reliquia Virgen de Coromoto</h3></div></div>
                    </div>
                </div>
                <div className="row">
                    <div class="col-md-6 d-xl-none d-md-none d-sm-none d-none d-sm-none d-lg-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="450" height="350" src="https://www.youtube.com/embed/w9PS__-EiQA?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Testimonio del Presbítero Allender Hernandez Rector de la Basílica Menor de Nuestra Señora de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Testimonio del Presbítero Allender Hernandez Rector de la Basílica Menor de Nuestra Señora de Coromoto 03 Febrebro del 2024</h3></div></div>
                    </div>
                    <div class="col-md-6 d-xl-none d-md-none d-sm-none d-none d-sm-none d-lg-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="450" height="350" src="https://www.youtube.com/embed/aYDscqFGv_4?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Hallazgos Santa Reliquia Virgen de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Hallazgos Santa Reliquia Virgen de Coromoto</h3></div></div>
                    </div>
                </div>

                <div className="row">
                    <div class="col-md-6 d-xl-none d-lg-none d-sm-none d-none d-md-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="350" height="300" src="https://www.youtube.com/embed/w9PS__-EiQA?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Testimonio del Presbítero Allender Hernandez Rector de la Basílica Menor de Nuestra Señora de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Testimonio del Presbítero Allender Hernandez Rector de la Basílica Menor de Nuestra Señora de Coromoto 03 Febrebro del 2024</h3></div></div>
                    </div>
                    <div class="col-md-6 d-xl-none d-lg-none d-sm-none d-none d-md-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="350" height="300" src="https://www.youtube.com/embed/aYDscqFGv_4?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Hallazgos Santa Reliquia Virgen de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Hallazgos Santa Reliquia Virgen de Coromoto</h3></div></div>
                    </div>
                </div>

                <div className="row">
                    <div class="col-md-5 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="500" height="400" src="https://www.youtube.com/embed/w9PS__-EiQA?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Testimonio del Presbítero Allender Hernandez Rector de la Basílica Menor de Nuestra Señora de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Testimonio del Presbítero Allender Hernandez Rector de la Basílica Menor de Nuestra Señora de Coromoto 03 Febrebro del 2024</h3></div></div>
                    </div>
                    <div class="col-md-2 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">&nbsp;</div>
                    <div class="col-md-5 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="500" height="400" src="https://www.youtube.com/embed/aYDscqFGv_4?autoplay=0" 
                        allowfullscreen="allowfullscreen" title="Hallazgos Santa Reliquia Virgen de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Hallazgos Santa Reliquia Virgen de Coromoto</h3></div></div>
                    </div>
                </div>

                <div className="row">
                    <div class="col-md-5 d-xl-none d-lg-none d-sm-none d-md-none d-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="100%" height="300" src="https://www.youtube.com/embed/w9PS__-EiQA?autoplay=0" 
                        title="Testimonio del Presbítero Allender Hernandez Rector de la Basílica Menor de Nuestra Señora de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Testimonio del Presbítero Allender Hernandez Rector de la Basílica Menor de Nuestra Señora de Coromoto 03 Febrebro del 2024</h3></div></div>
                    </div>
                    <div class="col-md-2 d-xl-none d-lg-none d-sm-none d-md-none d-block" align="center">&nbsp;</div>
                    <div class="col-md-5 d-xl-none d-lg-none d-sm-none d-md-none d-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="100%" height="300" src="https://www.youtube.com/embed/aYDscqFGv_4?autoplay=0" 
                         title="Hallazgos Santa Reliquia Virgen de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Hallazgos Santa Reliquia Virgen de Coromoto</h3></div></div>
                    </div>
                </div>


                 {/* Block 4 */}

                <div className="row">      
                    <div class="col-md-12 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">
                        <div className="row"><div class="col-12"><iframe  width="500" height="400" src="https://www.youtube.com/embed/vebhVq9oozE" 
                         title="Conferencia sra María García de Fleury en el Santuario 03 de febrero de 2023 Historia de la Virgen de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-12"><h3>Conferencia sra María García de Fleury en el Santuario 03 de febrero de 2023 Historia de la Virgen de Coromoto</h3></div></div>
                    </div>   
                    <div class="col-md-12 d-xl-none d-lg-none d-sm-none d-md-none d-block" align="center">
                        <div className="row"><div class="col-12"><iframe width="100%" height="300" src="https://www.youtube.com/embed/vebhVq9oozE" 
                         title="Conferencia sra María García de Fleury en el Santuario 03 de febrero de 2023 Historia de la Virgen de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-12"><h3>Conferencia sra María García de Fleury en el Santuario 03 de febrero de 2023 Historia de la Virgen de Coromoto</h3></div></div>
                    </div>                                    
                </div>

                 {/* Block 5 */}


                <div className="row">
                    <div class="col-md-6 d-lg-none d-md-none d-sm-none d-none d-xl-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="550" height="390" src="https://www.youtube.com/embed/OVQbfCyzmFA" 
                        allowfullscreen="allowfullscreen" title="Santuario Nacional Nuestra Señora de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Santuario Nacional Nuestra Señora de Coromoto</h3></div></div>
                    </div>
                    <div class="col-md-6 d-lg-none d-md-none d-sm-none d-none d-xl-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="550" height="390" src="https://www.youtube.com/embed/UMHhv1e1tR8" 
                        allowfullscreen="allowfullscreen" title="El revelador descubrimiento de las reliquia de la Virgen de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>El revelador descubrimiento de las reliquia de la Virgen de Coromoto</h3></div></div>
                    </div>
                </div> 
                <div className="row">
                    <div class="col-md-6 d-xl-none d-md-none d-sm-none d-none d-sm-none d-lg-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="450" height="350" src="https://www.youtube.com/embed/OVQbfCyzmFA" 
                        allowfullscreen="allowfullscreen" title="Santuario Nacional Nuestra Señora de Coromoto"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Santuario Nacional Nuestra Señora de Coromoto</h3></div></div>
                    </div>
                    <div class="col-md-6 d-xl-none d-md-none d-sm-none d-none d-sm-none d-lg-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="450" height="350" src="https://www.youtube.com/embed/UMHhv1e1tR8" 
                        allowfullscreen="allowfullscreen" title="El revelador descubrimiento de las reliquia de la Virgen de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>El revelador descubrimiento de las reliquia de la Virgen de Coromoto</h3></div></div>
                    </div>
                </div> 
                <div className="row">
                    <div class="col-md-6 d-xl-none d-lg-none d-sm-none d-none d-md-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="350" height="300" src="https://www.youtube.com/embed/OVQbfCyzmFA" 
                        allowfullscreen="allowfullscreen" title="Santuario Nacional Nuestra Señora de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Santuario Nacional Nuestra Señora de Coromoto</h3></div></div>
                    </div>
                    <div class="col-md-6 d-xl-none d-lg-none d-sm-none d-none d-md-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="350" height="300" src="https://www.youtube.com/embed/UMHhv1e1tR8" 
                        allowfullscreen="allowfullscreen" title="El revelador descubrimiento de las reliquia de la Virgen de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>El revelador descubrimiento de las reliquia de la Virgen de Coromoto</h3></div></div>
                    </div>
                </div>        
                <div className="row">
                    <div class="col-md-5 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="500" height="400" src="https://www.youtube.com/embed/OVQbfCyzmFA" 
                        allowfullscreen="allowfullscreen" title="Santuario Nacional Nuestra Señora de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Santuario Nacional Nuestra Señora de Coromoto</h3></div></div>
                    </div>
                    <div class="col-md-2 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">&nbsp;</div>
                    <div class="col-md-5 d-xl-none d-lg-none d-md-none d-none d-sm-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe  width="500" height="400" src="https://www.youtube.com/embed/UMHhv1e1tR8" 
                        allowfullscreen="allowfullscreen" title="El revelador descubrimiento de las reliquia de la Virgen de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>El revelador descubrimiento de las reliquia de la Virgen de Coromoto</h3></div></div>
                    </div>
                </div>        
                <div className="row">
                    <div class="col-md-5 d-xl-none d-lg-none d-sm-none d-md-none d-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe width="100%" height="300" src="https://www.youtube.com/embed/OVQbfCyzmFA" 
                         title="Santuario Nacional Nuestra Señora de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>Santuario Nacional Nuestra Señora de Coromoto</h3></div></div>
                    </div>
                    <div class="col-md-2 d-xl-none d-lg-none d-sm-none d-md-none d-block" align="center">&nbsp;</div>
                    <div class="col-md-5 d-xl-none d-lg-none d-sm-none d-md-none d-block" align="center">
                        <div className="row"><div class="col-md-12"><iframe width="100%" height="300" src="https://www.youtube.com/embed/UMHhv1e1tR8" 
                         title="El revelador descubrimiento de las reliquia de la Virgen de Coromoto"
                        allow="accelerometer;  clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe></div></div>
                        <div className="row"><div class="col-md-12"><h3>El revelador descubrimiento de las reliquia de la Virgen de Coromoto</h3></div></div>
                    </div>
                </div>              


                <div className="row">
                    <div className="col-12">&nbsp;</div>
                </div>




            </div>
            <Footer />
        </div>);
    }

}