/**
 * DonationsSanctuary 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 18/02/2021
 */

import React, { Component } from 'react';
import Header from './header';
import Footer from './footer';
import {Helmet} from "react-helmet";
import { Trans } from '@lingui/macro';
import { Redirect } from 'react-router-dom';

export default class IntroHolyRosary extends Component {

    constructor(props) {
        super(props);
        let language = this.props.language != null ? this.props.language : (this.props.location.pathname.replace("/", "").includes("intro-holy-rosary.html") ? "en" : "es");
        let onLanguageChange = this.props.onLanguageChange != null ? this.props.onLanguageChange : null;
        if (sessionStorage.getItem('language') !== null) {         
            this.state = { lang: sessionStorage.getItem('language'), eventChangeLanguage: onLanguageChange };        
        }
        else {
            this.state = {lang: language, eventChangeLanguage: onLanguageChange };     
        }
        this.refreshLanguage = this.refreshLanguage.bind(this);
        this.invokeChangeLanguage = this.invokeChangeLanguage.bind(this);
    }

    componentDidMount() {
        if ((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') || (this.state.lang!==null && this.state.lang==='en')) {            
            if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {              
               this.invokeChangeLanguage((sessionStorage.getItem('language') !== null && sessionStorage.getItem('language')!=='null') ? sessionStorage.getItem('language') : this.state.lang);  
            }
        }        
    }

    invokeChangeLanguage(language) {
        if(this.state.eventChangeLanguage!==null && typeof this.state.eventChangeLanguage !== "undefined") {                         
            this.setState({ lang: language });         
            sessionStorage.setItem('language', language);        
            this.state.eventChangeLanguage(language);            
        }
    }

    refreshLanguage(value) {            
        if (value !== null && value !== '') {
            this.setState({ lang: value });                    
        }
    }

    render() {

        let helmetComp, linkHolyRosary;
        if (this.state.lang === 'es') {
            //seo 
            helmetComp = <Helmet>
            <title>Introducci&oacute;n El Santo Rosario</title>
            <meta name="description" content="Introducci&oacute;n El Santo Rosario" />   
            <meta name="keywords" content="santo, rosario" />            
            </Helmet>;
            linkHolyRosary = <div className="col-12" align="center"><h2><a href="santo-rosario.html">Iniciar El Santo Rosario</a></h2></div>;
        }
        else {
            //seo 
            helmetComp = <Helmet>
                <title>Intro Holy Rosary</title>
                <meta name="description" content="Intro The Holy Rosary" />
                <meta name="keywords" content="holy, rosary" />
            </Helmet>;
            linkHolyRosary = <div className="col-12" align="center"><h2><a href="holy-rosary.html">Star The Holy Rosary</a></h2></div>;
        }

        return <Redirect to={'santo-rosario.html'} />;
    }

}