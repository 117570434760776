/**
 * Index 
 * www.santuariobasilicacoromoto.com
 * @author yadicksonvasquez@gmail.com
 * @updated 12/02/2024
 */

import React, {Component} from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import xl_mainslider from '../img/gallery-holy-relic/3400_1500/Main-Slider-Dic-2023.jpg';
import xl_mainslider2 from '../img/sanctuary/sliderSantuario.jpg';
import xl_mainslider3 from '../img/gallery-holy-relic/3400_1500/santuario-basilica-coromoto.jpg';
import hallazgos3 from '../img/gallery-holy-relic/2176_850/santa-reliquia-coromoto-1450.jpg';
import hallazgos1 from '../img/gallery-holy-relic/2176_850/holy-relic-coromoto-370.jpg';
import CampoCoronacion463px from '../img/gallery-holy-relic/480/CampoCoronacion463px.jpg';
import OurLadyCoromotoMain from '../img/gallery-holy-relic/480/OurLadyCoromoto2022-2px.jpg';
import OurLadyCoromoto2023 from '../img/gallery-holy-relic/480/OurLadyCoromoto2024.jpg';
import OurLadyCoromotoMay2024 from '../img/gallery-holy-relic/480/HolyRelicOurLadyCoromoto.jpg';
import lg_slider2 from '../img/gallery-holy-relic/2400_960/santaReliquiaSlider2-2.jpg';
import lg_slider1 from '../img/gallery-holy-relic/2400_960/slider2400960_holyrelic-2.jpg';

export default class MainSlider extends Component {


    render() {
       

        return(
            <div className="row">
                  <div className="col-xl-12 d-md-none d-sm-none d-none d-lg-none d-xl-block" style={{'marginTop': '173px'}}>
                  <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false} interval={3000}>
                        <div>
                            <img src={xl_mainslider} alt=""/>
                        </div>         
                        <div>
                            <img src={xl_mainslider2} alt=""/>
                        </div>  
                        <div>
                            <img src={xl_mainslider3} alt=""/>
                        </div>                                                                            
                    </Carousel>
                </div>
                <div className="col-xl-12 col-lg-12 d-sm-none d-none d-md-none d-xl-none d-lg-block" style={{'marginTop': '173px'}}>
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={lg_slider2} alt=""/>
                        </div>        
                        <div>
                            <img src={lg_slider1} alt=""/>
                        </div>                                                                              
                    </Carousel>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-block d-sm-block d-none" style={{'marginTop': '80px'}}>
                <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false}>
                        <div>
                            <img src={hallazgos1} alt="Virgen de Coromoto"/>
                        </div>    
                        <div>
                            <img src={hallazgos3} alt="Virgen de Coromoto"/>
                        </div>  
                    </Carousel>
                </div>
                <div className="col-12 d-xl-none d-lg-none d-md-none d-sm-none d-block" style={{'marginTop': '80px'}} align="center">
                    <Carousel showThumbs={false} autoPlay={true} infiniteLoop={true} showStatus={false} interval={5000}> 
                        <div>
                            <img src={OurLadyCoromotoMay2024} alt="Virgen de Coromoto"/>
                        </div>                     
                        <div>
                            <img src={OurLadyCoromoto2023} alt="Virgen de Coromoto"/>
                        </div>                     
                        <div>
                            <img src={CampoCoronacion463px} alt="Virgen de Coromoto"/>
                        </div>  
                                            
                    </Carousel>
                </div>
            </div>);
    }
    
}